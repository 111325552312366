import { useState } from 'react';

export const useOrder = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

	const updateOrderStatus = async (authToken, adminUsername, orderDetails, newStatus, user) => {
        var comm = 0;
        if (newStatus == "Matched") {
            comm = Number(orderDetails.totalAmount);
        }
        // Update Order Status
		const response = await fetch('https://api.epicjet.com.my/order/'+orderDetails._id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                status: newStatus,
                commission: comm,
                matchBy: adminUsername
            })
        })
        const json = await response.json();
        if (!response.ok) {
			setError(json.error);
		}
		if (response.ok) {
            if (newStatus == "Matched") {
                const updateWallet = await fetch('https://api.epicjet.com.my/user/' + user[0]._id, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    },
                    body: JSON.stringify({cashWallet: Number(user[0].cashWallet)+Number(orderDetails.totalAmount)})
                })
                const updateBal = await updateWallet.json();
                if (!updateWallet.ok) {
                    setIsLoading(false);
                    setError(updateBal.error);
                }
                if (updateWallet.ok) {
                    setIsLoading(false);
                }
            } else if (newStatus == "Error" || newStatus == "Cancelled") {
                const updateWallet = await fetch('https://api.epicjet.com.my/user/' + user[0]._id, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    },
                    body: JSON.stringify({cashWallet: Number(user[0].cashWallet)+Number(orderDetails.price)})
                })
                const updateBal = await updateWallet.json();
                if (!updateWallet.ok) {
                    setIsLoading(false);
                    setError(updateBal.error);
                }
                if (updateWallet.ok) {
                    setIsLoading(false);
                }
            }
		}
	};

    return { updateOrderStatus, isLoading, error };
}