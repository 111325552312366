import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { useOrder } from '../hooks/useOrder';
import { useAuthContext } from '../hooks/useAuthContext';

const Appointments = () => {

	const { admin } = useAuthContext();
	const { updateOrderStatus, isLoading, error } = useOrder();

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [statusOption, setStatusOption] = useState("");
	const [commAmount, setCommAmount] = useState(0);

	const [submitted, setSubmitted] = useState(false);

	const [userData, setUserData] = useState();
	const fetchUserData = async (selectedUsername) => {
		const response = await fetch('https://api.epicjet.com.my/user/' + selectedUsername, {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setUserData(json);
		console.log("fetch",json);
	}

	const [bookingData, setBookingData] = useState([]);
	const fetchBookingData = async () => {
		const response = await fetch('https://api.epicjet.com.my/booking/all', {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setBookingData(json);
	}


	useEffect(() => {
		fetchBookingData();
	}, []);

  	return (
		<>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Bookings</h3>
						<p className="mt-2 mb-0">Total {bookingData && bookingData.length} records</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive-xl table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">Booking ID</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Details</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Customer</p></th>
									{/* <th className="p-3" scope="col"><p className="mb-0">Merchant</p></th> */}
									<th className="p-3" scope="col"><p className="mb-0">Status</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Remark</p></th>
								</tr>
							</thead>
							<tbody>
								{bookingData && bookingData.map((booking, index) => (
									<tr key={index}>
										<td className="px-3 py-3">
											<p className="mb-0">{booking._id}</p>
											<p className="mb-0">{Moment(booking.createdAt).format('YYYY/MM/DD HH:mm')}H</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>{booking.package}</strong></p>
											<p className="mb-0">{booking.jet}</p>
											<p className="mb-0">{booking.date}</p>
											<p className="mb-0">{booking.time}</p>
											<p className="mb-0">{booking.location}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>Email: </strong>{booking.user_email}</p>
											<p className="mb-0"><strong>Phone: </strong>{booking.user_phone}</p>
										</td>
										{/* <td className="px-3 py-3">
											<p className="mb-0"><strong>Name: </strong>Pacers Sport Events</p>
											<p className="mb-0"><strong>Contact: </strong>{booking.user_phone}</p>
										</td> */}
										<td className="px-3 py-3">
											<p className="mb-0">
												{booking.status == "Processing" ? <span className="vip-yellow"><strong>Pending Payment</strong></span> : <></>}
											</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{booking.remark == "" ? "-" : booking.remark}</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  );

};

export default Appointments;