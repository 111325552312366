import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { useOrder } from '../../hooks/useOrder';
import { useAuthContext } from '../../hooks/useAuthContext';

const Commission = () => {

	const { admin } = useAuthContext();
	const { updateOrderStatus, isLoading, error } = useOrder();

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [statusOption, setStatusOption] = useState("");
	const [commAmount, setCommAmount] = useState(0);

	const [submitted, setSubmitted] = useState(false);

	const [userData, setUserData] = useState();
	const fetchUserData = async (selectedUsername) => {
		const response = await fetch('https://api.epicjet.com.my/user/' + selectedUsername, {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setUserData(json);
		console.log("fetch",json);
	}

	const [ordersData, setOrdersData] = useState([]);
	const fetchOrdersData = async () => {
		const response = await fetch('https://api.epicjet.com.my/order/', {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setOrdersData(json);
	}

	const createTransaction = async (e) => {
		e.preventDefault();
		if (statusOption === "") {
			return;
		}
		await updateOrderStatus(admin.token, admin.username, selectedOrder, statusOption, userData);
		fetchOrdersData();
		setSubmitted(true);
	};

	useEffect(() => {
		fetchOrdersData();
	}, []);

  	return (
		<>

            <div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Commission Report</h3>
						{/* <p className="mt-2 mb-0">Total {ordersData && ordersData.length} records</p> */}
                        <p className="mt-2 mb-0">Reporting tool will be available for admin only.</p>
					</div>
				</div>
			</div>

		</>
  );

};

export default Commission;