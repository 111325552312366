import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { useOrder } from '../hooks/useOrder';
import { useAuthContext } from '../hooks/useAuthContext';

const Merchant = () => {

	const { admin } = useAuthContext();
	const { updateOrderStatus, isLoading, error } = useOrder();

	const [submitted, setSubmitted] = useState(false);

	const [merchantData, setMerchantData] = useState([]);
	const fetchMerchantData = async () => {
		const response = await fetch('https://api.epicjet.com.my/merchant/', {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setMerchantData(json);
	}

	useEffect(() => {
		fetchMerchantData();
	}, []);

  	return (
		<>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Merchant / Agent</h3>
						<p className="mt-2 mb-0">Total {merchantData && merchantData.length} records</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive-xl table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">Merchant-ID</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Company</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Bank</p></th>
									<th className="p-3" scope="col"><p className="mb-0">PIC</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Emergency No.</p></th>
								</tr>
							</thead>
							<tbody>
								{merchantData && merchantData.map((merchant, index) => (
									<tr key={index}>
										<td className="px-3 py-3">
											<p className="mb-0">{merchant.merchant_id}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>{merchant.company}</strong></p>
											<p className="mb-0">{merchant.company_address}</p>
											<br></br>
											<p className="mb-0"><strong>Company PIC: </strong><br></br>{merchant.company_pic}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>{merchant.bank}</strong></p>
											<p className="mb-0">{merchant.bank_account_number}</p>
											<p className="mb-0">{merchant.bank_account_name}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{merchant.pic_name}</p>
											<p className="mb-0">{merchant.pic_contact}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{merchant.emergency_contact}</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  );

};

export default Merchant;